import { PickerValue } from 'antd-mobile/es/components/picker-view';
import { MadeOrderInputType } from 'codegen/generated/graphql';
import { atom } from 'jotai';
import { atomWithReset, atomWithStorage } from 'jotai/utils';

export interface IProductAtom {
  product: string;
  thumbnail: string;
  name: string;
  category: string;
  branch: string;
  price: number;
}

export interface IProductOptionState {
  [key: string]: {
    id: string | undefined | PickerValue;
    name: string | undefined | PickerValue;
  };
}

export interface IOrderAtom {
  product: string;
  productOptions: IProductOptionState;
  request: string;
}

export interface IBasketAtom {
  product: string;
  quantity: number;
  productOptions: IOrderAtom;
  request: string;
}

export interface ICoupon {
  id?: string;
  name?: string;
}

export interface IGift {
  id?: string;
  name?: string;
  price?: number;
}

export interface ISelectedProduct extends MadeOrderInputType {
  price: number;
}

export const selectedCouponAtom = atomWithReset<ICoupon>({});
export const selectedGiftAtom = atomWithReset<IGift>({});

export const productOptionsAtom = atomWithReset<IProductOptionState>({});
export const requestAtom = atomWithReset<string>('');

export const categoryTabKeyAtom = atomWithReset<number | string | null>(null);

export const selectedProductsInBasketAtom = atomWithReset<ISelectedProduct[]>(
  []
);

export const selectedOrderProductsAtom = atom((get) =>
  get(selectedProductsInBasketAtom).map(({ price, ...rest }) => ({
    ...rest,
  }))
);

export const selectedProductsAmountAtom = atom<number>((get) =>
  get(selectedProductsInBasketAtom).reduce(
    (prev, cur) => prev + cur.price * cur.quantity,
    0
  )
);

export const selectedProductsQuantityAtom = atom<number>((get) =>
  get(selectedProductsInBasketAtom).reduce(
    (prev, cur) => prev + cur.quantity,
    0
  )
);

export const activeGiftTypeAtom = atomWithReset<string>('congrats');
export const activeGiftCardAtom = atomWithReset<string>('');

export const productAtom = atomWithStorage<IProductAtom | null>(
  'product',
  null
);
export const orderAtom = atomWithStorage<IOrderAtom | null>('order', null);
export const giftReceiverAtom = atomWithStorage<string>('gift', '');

export const globalThemeColorAtom = atomWithStorage<string>('theme', '#ff66af');
export const prevPathAtom = atomWithStorage<string | null>('prevPath', null);
export const currentBranchAtom = atomWithStorage<string | null>(
  'currentBranch',
  null
);
