import React, { useEffect, useState } from 'react';
import 'styles/globals.css';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { AppProps } from 'next/app';
import { ConfigProvider } from 'antd-mobile';
import koKR from 'antd-mobile/es/locales/ko-KR';
import '@toast-ui/editor/dist/toastui-editor.css';
import { useRouter } from 'next/router';

import { useAtom } from 'jotai';
import { globalThemeColorAtom } from 'atom/store';
import hexToHSL from 'utils/hexToHSL';
import { RESET } from 'jotai/utils';
import Script from 'next/script';
import { isProd } from 'utils/config';

interface CustomAppProps {
  Component: AppProps['Component'] & {
    getLayout: (page: React.ReactNode) => React.ReactNode;
  };
  pageProps: AppProps['pageProps'];
}

function MyApp({ Component, pageProps }: CustomAppProps) {
  const router = useRouter();
  const { pathname } = router;
  const path = pathname.split('/')[1];
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 10 * 1000,
          },
        },
      })
  );

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page);

  const [globalThemeColor, setGlobalThemeColor] = useAtom(globalThemeColorAtom);
  const [styleState, setStyleState] = useState<React.CSSProperties>({});

  useEffect(() => {
    const hasThemeColorPage = ['basket', 'product', 'gift'].includes(path);
    if (!hasThemeColorPage) {
      setGlobalThemeColor(RESET);
    }
    const styles = {
      '--adm-color-primary': hasThemeColorPage ? globalThemeColor : '#ff66af',
      '--adm-color-text-secondary': hasThemeColorPage
        ? hexToHSL(globalThemeColor, true)
        : '#ff66af',
    } as React.CSSProperties;

    setStyleState(styles);

    return () => {
      setStyleState(styles);
    };
  }, [globalThemeColor, path]);

  return (
    <>
      {isProd && (
        <>
          <Script
            strategy="afterInteractive"
            src="https://www.googletagmanager.com/gtag/js?id=G-01JT90B9EJ"
          />
          <Script
            id="gtag-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-01JT90B9EJ', {
                  page_path: window.location.pathname,
                });
              `,
            }}
          />
        </>
      )}

      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <ConfigProvider locale={koKR}>
            <div style={{ ...styleState }}>
              {getLayout(<Component {...pageProps} />)}
            </div>
          </ConfigProvider>
        </Hydrate>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
