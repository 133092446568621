import { CookieSerializeOptions } from 'next/dist/server/web/types';

const RELEASE_ENV = process.env.NEXT_PUBLIC_RELEASE_ENV;
const isProd = process.env.NODE_ENV === 'production';

let API_BASE_URL = '';
let CALLBACK_URL = '';
let PID_URL = '';

switch (RELEASE_ENV) {
  case 'production':
    API_BASE_URL = 'https://cafe-admin.thepinkfong.com';
    CALLBACK_URL = 'https://cafe.thepinkfong.com/providers/pid/callback';
    PID_URL = 'https://pid.pinkfong.com';
    break;
  case 'staging':
    API_BASE_URL = 'https://cafe-admin-test.thepinkfong.com';
    CALLBACK_URL = 'https://cafe-test.thepinkfong.com/providers/pid/callback';
    PID_URL = 'https://pid-test.pinkfong.com';
    break;
  case 'development':
    API_BASE_URL = 'https://cafe-admin-test.thepinkfong.com';
    CALLBACK_URL = 'http://localhost:3000/providers/pid/callback';
    PID_URL = 'https://pid-test.pinkfong.com';
    break;
  default:
    break;
}

const defaultCookieConfig: CookieSerializeOptions = {
  path: '/',
  domain: '',
  secure: isProd,
  sameSite: 'lax',
};

export {
  isProd,
  API_BASE_URL,
  CALLBACK_URL,
  PID_URL,
  defaultCookieConfig,
  RELEASE_ENV,
};
